'use strict';

angular.module('windmanagerApp')
  .directive('placeHolder', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        $(element).placeholder();
      }
    };
  });
